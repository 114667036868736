@keyframes colorChange {
    0%, 100% {
      fill: #fc00ff;
      height: 2%
    }
    50% {
      fill: #00fffc;
      height: 150px
    }
  }
  
  .change {
    animation: colorChange 4s ease-in-out infinite;
  }

  @keyframes colorChange2 {
    0%, 100% {
      fill: #fc00ff;
      height: 5%
    }
    25% {
      fill: #00fffc;
      height: 80px
    }
    50% {
      fill: #fc00ff;
      height: 100px
    }
    75% {
      fill: #00fffc;
      height: 150px
    }
  }
  
  .change2 {
    animation: colorChange2 3s ease-in-out infinite;
  }

  @keyframes colorChange3 {
    0%, 100% {
      fill: #fc00ff;
      height: 20%
    }
    25% {
      fill: #00fffc;
      height: 10px
    }
    50% {
      fill: #fc00ff;
      height: 40px
    }
    75% {
      fill: #00fffc;
      height: 150px
    }
  }
  
  .change3 {
    animation: colorChange3 1.5s ease-in-out infinite;
  }

  .blinks1 {
    animation: blinker 5s ease-in-out infinite;
  }
  .blinks2 {
    animation: blinker 1.5s ease-in-out infinite;
  }
  .blinks3 {
    animation: blinker 2s ease-in-out infinite;
  }
  .blinks4 {
    animation: blinker 2.5s ease-in-out infinite;
  }
  .blinks5 {
    animation: blinker 3s ease-in-out infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

