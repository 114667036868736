@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000730;
  font-family: 'Play', sans-serif; /* Specify the font family name from Google Fonts */
}

::-webkit-scrollbar {
  width: 14px;
  display: none
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgb(16, 3, 88); 
  border-radius: 10px;
  display: none
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgb(33, 134, 173); 
  display: none
}

::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 2px rgb(33, 134, 173);
}

::-webkit-scrollbar-track-piece {
  display: none;
  -webkit-box-shadow: inset 0 0 2px rgb(67, 71, 131); 
}
::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  width: 13px;
  background-color: rgb(67, 71, 131); 
}
::webkit-sc

::-webkit-scrollbar-corner {
  background-color: rgb(67, 71, 131); 
}