.bgk1 {
    background: url("../asset/background/parallax-bg.png") no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 0;

}
.bgk1a {
  background: url("../asset/background/parallax-bg.png") no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;

}

.layer-1 {
    background: url("../asset/background/parallax-l1.png") no-repeat center center ;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 1;

}

.layer-2 {
    background: url("../asset/background/parallax-l2.png") no-repeat center center;
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 2;
}

@keyframes floating {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-30px);
    }
  }
  
  .float {
    animation: floating 3s ease-in infinite;
  }

  
  .animate-logo {
    animation: logo-move 3s forwards;
  }
  
  @keyframes logo-move {
    0% {
      transform: translateY(5%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  

.card-imgs {
  position: relative;
  background: url("../asset//background/1x/Asset\ 1.png") no-repeat center center;
}

.first {
  position: relative;
  background: url("../asset//background/1x/Asset\ 2.png") no-repeat center center;
  background-size: contain;
}

.shine {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50.5%;
  height: 100%;
  background:
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(112, 189, 240, 0.5) 25%,
      rgba(112, 189, 240, 0.5) 75%,
      rgba(255, 255, 255, 0) 100%
    );
  animation: shine 2s infinite;
  transform: translateX(-45%);
}

@keyframes shine {
  0% {
    opacity: 0.2;

  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity:0.2;
  }
}

.b123 {

  background: url("../asset/background/SVG/bordir.png");
  background-repeat: repeat-x;
  background-size: contain;
}


