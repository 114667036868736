.str0 {stroke:aqua;stroke-width:2.29;stroke-miterlimit:2.61313;stroke-opacity:0.501961}
.str1 {stroke:aqua;stroke-width:2.29;stroke-miterlimit:2.61313}
.str2 {stroke:black;stroke-width:0.66;stroke-miterlimit:2.61313}
.str3 {stroke:aqua;stroke-width:0.66;stroke-miterlimit:2.61313}
.fil4 {fill:none}
.fil1 {fill:none;fill-rule:nonzero}
.fil3 {fill:aqua}
.fil5 {fill:black;fill-rule:nonzero}
.fil7 {fill:aqua;fill-rule:nonzero}
.fil6 {fill:white;fill-rule:nonzero}
.fil2 {fill:blue;fill-rule:nonzero;fill-opacity:0.501961}
.fil0 {fill:aqua;fill-rule:nonzero;fill-opacity:0.501961}