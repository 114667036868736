/* CSS */

#Vector_1, #Vector_2, #Vector_3, #Vector_4 {
    stroke: #fff;
    stroke-width: 2;
  }

.vector1:hover #Vector_1 {
    transition: 2s;
    transform: translateX(-20px) translateY(-20px);
  }
  
  .vector1:hover #Vector_2 {
    transition: 2s;
    transform: translateX(-20px) translateY(20px);
  }
  
  .vector1:hover #Vector_3 {
    transition: 2s;
    transform: translateX(20px) translateY(-20px);
  }
  
  .vector1:hover #Vector_4 {
    transition: 2s;
    transform: translateX(20px) translateY(20px);
  }