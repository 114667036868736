.cb-btn {
    position: relative;
  
    
    /* Other styles */
  }
  
  .corners > i {
    position: absolute;
    width: 10px;
    height: 10px;
  
  }
  
  .corners > i:nth-child(1) {
    top: -1px;
    left: -1px;
    border-top: 2px solid black;
    border-left: 2px solid black;
  }
  
  .corners > i:nth-child(2) {
    top: -1px;
    right: -1px;
    border-top: 2px solid black;
    border-right: 2px solid black;
  }
  
  .corners > i:nth-child(3) {
    bottom: -1px;
    right: -1px;
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }
  
  .corners > i:nth-child(4) {
    bottom: -1px;
    left: -1px;
    border-bottom: 2px solid black;
    border-left: 2px solid black;
  }