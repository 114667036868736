/* Navigation.css */
.modal-announcement {
    transition: transform 0.3s ease-out;
  }
  
  .slide-in {
    transform: translateY(0);
    display: block;
  }
  
  .slide-out {
    transform: translateY(-100%);
    display: none;
  }