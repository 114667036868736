:root {
	--primary: #D0B050;
	--secondary: hsl(224, 20%, 17%);
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.stripe-btn {
	--text-shadow-thickness: 0.05ch;
	--text-shadow-negative-thickness: calc(-1 * var(--text-shadow-thickness));
	position: relative;
    display: flex; 
    justify-content: center;
    align-items: center;
	border: 2px solid var(--primary);
	font-size: 3rem;
	color: var(--primary);
	text-transform: uppercase;
	text-shadow:
		var(--text-shadow-thickness) var(--text-shadow-thickness) 0px var(--secondary),
		var(--text-shadow-thickness) var(--text-shadow-negative-thickness) 0px var(--secondary),
		var(--text-shadow-negative-thickness) var(--text-shadow-thickness) 0px var(--secondary),
		var(--text-shadow-negative-thickness) var(--text-shadow-negative-thickness) 0px	var(--secondary);
}

.corners {
	--corner-dimension: 0.7ch;
	--corner-thickness: 3px;
	--corner-negative-thickness: calc(-1 * var(--corner-thickness));
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.corners > i {
	content: "";
	position: absolute;
	width: var(--corner-dimension);
	height: var(--corner-dimension);
}

.corners > i:nth-child(1) {
	top: 0;
	left: 0;
	box-shadow: inset var(--corner-thickness) var(--corner-thickness) 0	var(--primary);
}

.corners > i:nth-child(2) {
	top: 0;
	right: 0;
	box-shadow: inset var(--corner-negative-thickness) var(--corner-thickness) 0 var(--primary);
}

.corners > i:nth-child(3) {
	bottom: 0;
	right: 0;
	box-shadow: inset var(--corner-negative-thickness) var(--corner-negative-thickness) 0 var(--primary);
}

.corners > i:nth-child(4) {
	bottom: 0;
	left: 0;
	box-shadow: inset var(--corner-thickness) var(--corner-negative-thickness) 0 var(--primary);
}

.stripes {
	--stripes-position: 0.3ch;
	content: "";
	position: absolute;
	display: flex;
	justify-content: space-between;
	height: calc(100% - var(--stripes-position) * 2);
	width: calc(100% - var(--stripes-position) * 2);
	top: var(--stripes-position);
	left: var(--stripes-position);
	overflow: hidden;
}

.stripes > i {
	content: "";
	height: calc(100% + 2ch);
	width: 0.5ch;
	background: var(--primary);
	opacity: 0.3;
	transform: translateY(-1ch) rotate(37deg);
	transition: all 0.2s cubic-bezier(0.85, 0, 0.15, 1)
}

.stripes > i:nth-child(1) {transition-delay: 0.02s;}
.stripes > i:nth-child(2) {transition-delay: 0.04s;}
.stripes > i:nth-child(3) {transition-delay: 0.06s;}
.stripes > i:nth-child(4) {transition-delay: 0.08s;}
.stripes > i:nth-child(5) {transition-delay: 0.10s;}
.stripes > i:nth-child(6) {transition-delay: 0.12s;}
.stripes > i:nth-child(7) {transition-delay: 0.14s;}
.stripes > i:nth-child(8) {transition-delay: 0.16s;}
.stripes > i:nth-child(9) {transition-delay: 0.18s;}
.stripes > i:nth-child(10) {transition-delay: 0.20s;}
.stripes > i:nth-child(11) {transition-delay: 0.22s;}
.stripes > i:nth-child(12) {transition-delay: 0.24s;}
.stripes > i:nth-child(13) {transition-delay: 0.26s;}
.stripes > i:nth-child(14) {transition-delay: 0.28s;}
.stripes > i:nth-child(15) {transition-delay: 0.3s;}
.stripes > i:nth-child(16) {transition-delay: 0.32s;}
.stripes > i:nth-child(17) {transition-delay: 0.34s;}
.stripes > i:nth-child(18) {transition-delay: 0.36s;}
.stripes > i:nth-child(19) {transition-delay: 0.38s;}
.stripes > i:nth-child(20) {transition-delay: 0.4s;}

.stripe-btn:hover .stripes > i {opacity: 0.5;}

.label {
	position: relative;
	z-index: 10;
}

.no-pointer-events {
    pointer-events: none;
  }

  .stripes-auto-animate .stripes > i {
    opacity: 1;
    animation: stripe-animation 1s infinite;
  }
  
  .stripes-auto-animate .stripes > i:nth-child(1) { animation-delay: 0.02s; }
  .stripes-auto-animate .stripes > i:nth-child(2) { animation-delay: 0.04s; }
  .stripes-auto-animate .stripes > i:nth-child(3) {transition-delay: 0.06s;}
.stripes-auto-animate .stripes > i:nth-child(4) {animation-delay: 0.08s;}
.stripes-auto-animate .stripes > i:nth-child(5) {animation-delay: 0.10s;}
.stripes-auto-animate .stripes > i:nth-child(6) {animation-delay: 0.12s;}
.stripes-auto-animate .stripes > i:nth-child(7) {animation-delay: 0.14s;}
.stripes-auto-animate .stripes > i:nth-child(8) {animation-delay: 0.16s;}
.stripes-auto-animate .stripes > i:nth-child(9) {animation-delay: 0.18s;}
.stripes-auto-animate .stripes > i:nth-child(10) {animation-delay: 0.20s;}
.stripes-auto-animate .stripes > i:nth-child(11) {animation-delay: 0.22s;}
.stripes-auto-animate .stripes > i:nth-child(12) {animation-delay: 0.24s;}
.stripes-auto-animate .stripes > i:nth-child(13) {animation-delay: 0.26s;}
.stripes-auto-animate .stripes > i:nth-child(14) {animation-delay: 0.28s;}
.stripes-auto-animate .stripes > i:nth-child(15) {animation-delay: 0.3s;}
.stripes-auto-animate .stripes > i:nth-child(16) {animation-delay: 0.32s;}
.stripes-auto-animate .stripes > i:nth-child(17) {animation-delay: 0.34s;}
.stripes-auto-animate .stripes > i:nth-child(18) {animation-delay: 0.36s;}
.stripes-auto-animate .stripes > i:nth-child(19) {animation-delay: 0.38s;}
.stripes-auto-animate .stripes > i:nth-child(20) {animation-delay: 0.4s;}
  
  @keyframes stripe-animation {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  }