#w {
  min-height: 100vh;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  image-rendering: pixelated;
  position: absolute;
  top: 0;
}
#space {
  width: 100%;
  height: 100%;
  z-index: 0;
}
#warp {
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20px auto;
  color: rgba(209, 255, 255, 1);
  border: 2px solid;
  padding: 1em;
  width: 10em;
  display: inline-block;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.8);
}
